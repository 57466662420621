<template>
  <Modal id="modal-login" v-slot="{ close, data }">
    <ModalShell size="md" :title="$t('login')" :close="close">
      <div class="p-4 lg:p-8 dark:bg-gray-900 dark:text-gray-300">
        <Login @success="handleSuccess(data), close()" />
      </div>
    </ModalShell>
  </Modal>
</template>

<script>
import { useFetchCustomer } from "@/composables/useFetchCustomer";

export default {
  emits: ["success"],
  methods: {
    async handleSuccess(data) {
      const { fetchCustomer } = useFetchCustomer();
      fetchCustomer(() => {
        this.$emit("success", data);
      });
    },
  },
};
</script>
